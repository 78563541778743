/**
 *
 * @author emil.ohman@svenskaspel.se (Emil Öhman)
 */

'use strict';

import gsap from 'gsap';
import { createPopper } from '@popperjs/core';
import { DialogConfirm } from 'trinidad-ui/dialog';
import DialogContextualArea from './contextual-area';
import { CONTEXTUAL } from '../../../dialog/assets/javascripts/constants/area';
import '../stylesheets/contextual-items.less';

class DialogContextual extends DialogConfirm {
  constructor(options) {
    options.icon = options.icon || false;
    options.area = options.area || CONTEXTUAL;

    super(options);
    this.log = window.svs ? new svs.core.log.Logger('DialogContextual') : console;
    this.zIndex = this.options.zIndex || 140;
    this.area = DialogContextualArea;
  }

  render() {
    super.render();

    this._arrowEl = document.createElement('div');
    this._arrowEl.className += 'arrow';
    this.el.appendChild(this._arrowEl);

    this.addPopper();
  }

  addPopper() {
    let attachTo = this.options.attachTo;

    if (window.jQuery && attachTo instanceof jQuery && attachTo.length > 0) {
      attachTo = attachTo[0];
    }

    createPopper(attachTo, this.el, {
      placement: 'bottom',
      strategy: 'fixed',
      modifiers: [
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: this._arrowEl
          }
        },
        {
          name: 'offset',
          options: {
            offset: [0, 17]
          }
        },
        {
          name: 'computeStyles',
          options: {
            gpuAcceleration: false,
            adaptive: false
          }
        },
        {
          name: 'preventOverflow',
          options: {
            boundary: 'window',
            padding: 12
          }
        }
      ]
    });
  }

  setupAnimations() {
    this.tl = gsap.timeline({
      paused: true,
      onComplete: () => {
        this.hasRevealed();
      },
      onReverseComplete: () => {
        this.hasClosed();
      }
    });
    this.tl.fromTo(
      this.el,
      0.2,
      {
        scale: 0.8,
        opacity: 0
      },
      {
        scale: 1,
        opacity: 1
      }
    );
    this.tl.fromTo(
      this.el,
      0.2,
      {
        boxShadow: '0'
      },
      {
        boxShadow: '0 0 48px 4px rgba(0, 0, 0, .5)'
      },
      '-=.1'
    );
    this.tl.fromTo(
      this._content,
      0.2,
      {
        autoAlpha: 0
      },
      {
        autoAlpha: 1
      }
    );
    this.tl.fromTo(
      this._buttons,
      0.2,
      {
        opacity: 0,
        y: 50
      },
      {
        opacity: 1,
        y: 0
      },
      '-=.1'
    );
  }
}

export default DialogContextual;
