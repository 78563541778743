/**
 * @author jimmie.larsson@svenskaspel.se (Jimmie Larsson)
 */

'use strict';

class PubSub {
  constructor() {
    this.callbacks = {};
  }

  emit(event, data) {
    if (this.callbacks[event]) {
      this.callbacks[event].forEach((callback) => {
        callback.callback.call(callback.context || this, data);
      });
    }
  }

  on(event, context, callback) {
    if (typeof context === 'function') {
      callback = context;
      context = undefined;
    }

    if (!this.callbacks[event]) {
      this.callbacks[event] = [];
    }
    this.callbacks[event].push({ context, callback });
  }

  off(event, context) {
    context = context || this;

    if (this.callbacks[event]) {
      for (let i = 0; i < this.callbacks[event].length; i++) {
        const item = this.callbacks[event][i];
        if (item.context === context) {
          this.callbacks[event].splice(i, 1);
          break;
        }
      }
    }
  }
}

export default PubSub;
