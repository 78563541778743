/**
 *
 * @author emil.ohman@svenskaspel.se (Emil Öhman)
 */

'use strict';

import gsap from 'gsap';
import DialogPubSub from '../../../dialog/assets/javascripts/events/pubsub';
import { CONTEXTUAL } from '../../../dialog/assets/javascripts/constants/area';

class DialogContextualArea extends DialogPubSub {
  constructor(options) {
    super();
    this.log = window.svs ? new svs.core.log.Logger('DialogContextualArea') : console;
    this.name = CONTEXTUAL;
    this.items = [];
    this.el = options.el;
    this.options = options;
  }

  init() {
    if (!this.el) {
      this.el = document.createElement('div');
      this.el.className +=
        'js-dialog-items dialog-items dialog-contextual-items js-dialog-contextual-items';
      this.el.setAttribute('aria-live', 'polite');

      document.body.appendChild(this.el);
    }
  }

  add(dialog) {
    this.init();

    this.el.classList.remove('dialog-contextual-items-closed');

    this.el.setAttribute('style', `z-index:${dialog.zIndex};`);

    dialog.el.className += ' dialog-popup-item dialog-contextual-item';

    dialog.allowDimmerClick = true;

    this.append(dialog);

    dialog.reveal();
  }

  close() {
    this.log.info('No more dialogs here, close container');
    this.el.classList.add('dialog-contextual-items-closed');
    document.body.removeChild(this.el);
    this.el = null;
    this.emit('close');
  }

  append(dialog) {
    this.log.info('Append dialog to be opened');
    // Add a11y features
    this.keyEventsListenerContextualRef = (event) => {
      this.keyEventsListenerContextual(event);
    };
    document.addEventListener('keydown', this.keyEventsListenerContextualRef);
    dialog._queue = this;

    dialog.on('closed', this, () => {
      // Remove a11y features
      document.removeEventListener('keydown', this.keyEventsListenerContextualRef);
      this.log.info('A dialog was closed, now handle it.');
      this.unload(dialog);
    });

    this.items.push(dialog);
    this.el.appendChild(dialog.el);
  }

  // Keyboard listener for contextual dialog
  keyEventsListenerContextual(e) {
    // Close contextual dialog on enter or escape.
    if ((e.keyCode && e.keyCode === 27 || e.key && e.key.toLowerCase() === 'esc' || e.key && e.key.toLowerCase() === 'escape') ||
      e.code && e.code.toLowerCase() === 'enter' || e.key && e.key.toLowerCase() === 'enter') {
      this.closeFromOutside();
    }
  }

  unload(dialog) {
    this.items.forEach((item, i) => {
      if (item._id === dialog._id) {
        this.items.splice(i, 1);
        gsap.to(item.el, 0.2, {
          opacity: 0,
          onComplete: () => {
            this.el.removeChild(item.el);
            if (!this.items.length) {
              this.close();
            }
          }
        });
        item._rendered = false;
      }
    });
  }

  closeFromOutside() {
    if (this.items[0]) {
      this.items[0].close();
    }
  }

  status() {
    this.log.info(`${this.name} queue, ${this.items.length} still remain`);
  }

  count() {
    return this.items.length;
  }
}

export default DialogContextualArea;
